// ErrorPage.js
import React from "react";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <div style={styles.pageContainer}>
      <h1 style={styles.title}>Report an Error</h1>
      <p style={styles.text}>
        If you encountered an error, please email:{" "}
        <a href="mailto:a.virakthi@gmail.com" style={styles.link}>
          a.virakthi@gmail.com
        </a>
        <br/ >
        Thank you!
      </p>
      <button style={styles.backButton} onClick={() => navigate("/")}>
        ⬅ Back to Landing Page
      </button>
    </div>
  );
};

const styles = {
  pageContainer: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
    padding: "20px",
  },
  title: {
    fontSize: "2rem",
    marginBottom: "20px",
    color: "#681e19",
  },
  text: {
    fontSize: "1.2rem",
    marginBottom: "40px",
    color: "#333",
  },
  link: {
    color: "#681e19",
    textDecoration: "none",
    fontWeight: "bold",
  },
  backButton: {
    backgroundColor: "#681e19",
    color: "white",
    border: "none",
    padding: "10px 20px",
    fontSize: "1rem",
    cursor: "pointer",
    borderRadius: "5px",
    transition: "background-color 0.3s ease",
  },
};

export default ErrorPage;

