import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function App() {
  const [registration, setRegistration] = useState("");
  const [mileage, setMileage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (!registration.trim()) {
      alert("❌ Please enter a valid registration number.");
      return;
    }
    if (!mileage.trim()) {
      alert("❌ Please enter the mileage.");
      return;
    }

    navigate(`/results?registration=${registration}&mileage=${mileage}`);
  };

  return (
    <div style={styles.background}>
      {/* Global CSS for selective hover effects */}
      <style>
        {`
          /* Registration and Mileage: default black; on hover, turn #681e19 with scaling */
          .hoverEffect {
            color: black !important;
            transition: color 0.3s ease, font-size 0.3s ease;
          }
          .hoverEffect:hover {
            color: #681e19 !important;
            font-size: 125% !important;
          }
          /* LinkedIn link: default #681e19; on hover, turn white (no scaling) */
          .linkedinHover {
            color: #681e19 !important;
            transition: color 0.3s ease;
          }
          .linkedinHover:hover {
            color: white !important;
          }
        `}
      </style>
      <div style={styles.overlay}>
        <div style={styles.container}>
          {/* Heading now permanently in #681e19 */}
          <h2 style={styles.title}>Find Common Faults In Your Car</h2>

          <div style={styles.inputGroup}>
            <label style={styles.label} className="hoverEffect">
              Registration Number:
            </label>
            <input
              type="text"
              value={registration}
              onChange={(e) => setRegistration(e.target.value.toUpperCase())}
              placeholder="Enter registration (e.g., AA51AAA)"
              style={styles.input}
              className="hoverEffect"
              required
            />
          </div>

          <div style={styles.inputGroup}>
            <label style={styles.label} className="hoverEffect">
              Mileage:
            </label>
            <input
              type="number"
              value={mileage}
              onChange={(e) => setMileage(e.target.value)}
              placeholder="Enter mileage"
              style={styles.input}
              className="hoverEffect"
              required
            />
          </div>

          <button 
            onClick={handleSubmit} 
            style={styles.button}
            onMouseEnter={(e) => e.target.style.backgroundColor = "#872d25"}
            onMouseLeave={(e) => e.target.style.backgroundColor = "#681e19"}
          >
            Find Faults
          </button>
        </div>
      </div>

      {/* 🔙 Back Button (Centered Above Footer) */}
      <div style={styles.backButtonContainer}>
        <button 
          onClick={() => navigate("/")} 
          style={styles.backButton}
          onMouseEnter={(e) => e.target.style.backgroundColor = "#872d25"}
          onMouseLeave={(e) => e.target.style.backgroundColor = "#681e19"}
        >
          ⬅ Back to Landing Page
        </button>
      </div>

      {/* Footer */}
      <div style={styles.footer}>
        <p>
          Connect with me on{" "}
          <a 
            href="https://www.linkedin.com/in/adithyavirakthi" 
            target="_blank" 
            rel="noopener noreferrer" 
            style={styles.link}
            className="linkedinHover"
          >
            LinkedIn
          </a>
        </p>
      </div>
    </div>
  );
}

// 🔹 Define styles
const styles = {
  background: {
    backgroundImage: "url('/icons/homebackground.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center center", 
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", 
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    background: "rgba(235, 235, 235, 0.85)",  
    padding: "2rem",
    borderRadius: "10px",
    textAlign: "center",
    width: "90vw",
    maxWidth: "400px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: "bold",
    marginBottom: "15px",
    textTransform: "uppercase",
    textAlign: "center",
    color: "#681e19", // Heading text color set to #681e19 permanently
  },
  inputGroup: {
    width: "100%",
    textAlign: "left",
    marginBottom: "10px",
  },
  label: {
    fontSize: "1rem",
    fontWeight: "bold",
    display: "block",
    marginBottom: "5px",
  },
  input: {
    width: "100%",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontSize: "1rem",
  },
  button: {
    backgroundColor: "#681e19",
    color: "white",
    border: "none",
    padding: "12px 20px",
    fontSize: "1rem",
    cursor: "pointer",
    borderRadius: "5px",
    marginTop: "15px",
    transition: "background-color 0.3s ease-in-out",
  },
  backButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    position: "absolute",
    bottom: "70px",
  },
  backButton: {
    backgroundColor: "#681e19",
    color: "white",
    border: "none",
    padding: "12px 20px",
    fontSize: "1rem",
    cursor: "pointer",
    borderRadius: "5px",
    transition: "background-color 0.3s ease-in-out",
  },
  footer: {
    textAlign: "center",
    padding: "10px",
    fontSize: "0.9rem",
    backgroundColor: "rgba(0, 0, 0, 0.8)",  
    color: "white",
    position: "absolute",
    bottom: "0",
    width: "100%",
  },
  link: {
    textDecoration: "none",
    fontWeight: "bold",
  },
};

export default App;


