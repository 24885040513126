import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Base from "./Base";  // ✅ The main app component handling routing
import './index.css';


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter> {/* ✅ The ONLY Router in the app */}
      <Base /> 
    </BrowserRouter>
  </React.StrictMode>
);
