import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function Landing() {
  const navigate = useNavigate();
  const [hoverTop, setHoverTop] = useState(false);
  const [hoverBottom, setHoverBottom] = useState(false);
  const [hoverLogo, setHoverLogo] = useState(false);
  const [showPopup, setShowPopup] = useState(true);

  return (
    <div style={styles.container}>
      {showPopup && (
        <div style={styles.popupOverlay}>
          <div style={styles.popup}>
            <img 
              src="/icons/landinglogo.png" 
              alt="Landing Logo" 
              style={styles.popupLogo}
            />
            <div style={styles.popupContent}>
              <p>
                Car-Sense gives you peace of mind when buying a used car. Simply tap the top half of the landing page for an instant registration lookup. If the registration isn't available, you can manually enter the car details in the lower section.
              </p>
              <p>
                For the best experience, we recommend using the automatic registration lookup, as it provides MOT history along with key insights. You'll also get a list of common faults specific to your car and guidance on where to check for potential issues.
              </p>
              <p>
                Make informed decisions with ease. Happy car hunting!
              </p>
            </div>
            <button style={styles.popupButton} onClick={() => setShowPopup(false)}>
              Got it!
            </button>
          </div>
        </div>
      )}

      {/* 🔹 Center Logo Section */}
      <div style={styles.centerOverlay}>
        <img 
          src="/icons/landinglogo.png" 
          alt="Landing Logo" 
          style={{
            ...styles.logo,
            transform: hoverLogo ? "scale(1.5)" : "scale(1)"
          }}
          onMouseEnter={() => setHoverLogo(true)}
          onMouseLeave={() => setHoverLogo(false)}
        />
      </div>

      {/* 🔹 Top Section - Registration Search */}
      <div
        style={{
          ...styles.imageContainer,
          transform: hoverTop ? "scale(1.1)" : "scale(1)",
          filter: hoverTop ? "brightness(50%)" : "brightness(100%)",
        }}
        onMouseEnter={() => setHoverTop(true)}
        onMouseLeave={() => setHoverTop(false)}
        onClick={() => navigate("/registration-search")}
      >
        <img src="/icons/right.jpg" alt="Search by Registration" style={styles.image} />
        {hoverTop && <h2 style={styles.title}>Search by Registration</h2>}
      </div>

      {/* 🔹 Bottom Section - Manual Search */}
      <div
        style={{
          ...styles.imageContainer,
          transform: hoverBottom ? "scale(1.1)" : "scale(1)",
          filter: hoverBottom ? "brightness(50%)" : "brightness(100%)",
        }}
        onMouseEnter={() => setHoverBottom(true)}
        onMouseLeave={() => setHoverBottom(false)}
        onClick={() => navigate("/manual-search")}
      >
        <img src="/icons/left.jpg" alt="Enter Details Manually" style={styles.image} />
        {hoverBottom && <h2 style={styles.title}>Enter Details Manually</h2>}
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    height: "100vh",
    backgroundColor: "white",
    overflowX: "hidden",
    position: "relative",
  },
  centerOverlay: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10,
  },
  logo: {
    height: "100px",
    transition: "transform 0.3s ease-in-out",
  },
  imageContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    transition: "all 0.3s ease-in-out",
  },
  title: {
    position: "absolute",
    color: "white",
    fontSize: "24px",
    fontWeight: "bold",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
  },
  popupOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 20,
  },
  popup: {
    backgroundColor: "rgba(128, 128, 128, 0.8)",
    border: "1px solid #681e19",
    borderRadius: "10px",
    padding: 0, // no visible padding
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "80%",
    maxWidth: "400px",
    textAlign: "center",
  },
  popupLogo: {
    width: "80px",
    marginTop: "10px",
  },
  popupContent: {
    margin: "10px",
  },
  popupButton: {
    backgroundColor: "#681e19",
    color: "white",
    border: "none",
    borderRadius: "4px",
    padding: "10px 20px",
    margin: "10px",
    cursor: "pointer",
  },
};

export default Landing;
