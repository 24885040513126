import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

function ManualResults() {
  const location = useLocation();
  const navigate = useNavigate();

  // Log the location state and search string for debugging
  console.log("Location state:", location.state);
  console.log("Location search:", location.search);

  const queryParams = new URLSearchParams(location.search);

  // Construct vehicleInfo using state first, then query parameters, defaulting to "N/A"
  const vehicleInfo = {
    brand:
      (location.state &&
        location.state.vehicleInfo &&
        location.state.vehicleInfo.brand) ||
      queryParams.get("brand") ||
      "N/A",
    model:
      (location.state &&
        location.state.vehicleInfo &&
        location.state.vehicleInfo.model) ||
      queryParams.get("model") ||
      "N/A",
    variant:
      (location.state &&
        location.state.vehicleInfo &&
        location.state.vehicleInfo.variant) ||
      queryParams.get("variant") ||
      "N/A",
    year:
      (location.state &&
        location.state.vehicleInfo &&
        location.state.vehicleInfo.year) ||
      queryParams.get("year") ||
      "N/A",
    miles:
      (location.state &&
        location.state.vehicleInfo &&
        location.state.vehicleInfo.miles) ||
      queryParams.get("miles") ||
      "N/A",
    gearbox:
      (location.state &&
        location.state.vehicleInfo &&
        location.state.vehicleInfo.gearbox) ||
      queryParams.get("gearbox") ||
      "N/A",
  };

  // Retrieve faults from navigation state if available
  const initialFaults =
    (location.state && location.state.faults) ? location.state.faults : [];
  const [faults, setFaults] = useState(initialFaults);
  const [loading, setLoading] = useState(initialFaults.length === 0);
  const [error, setError] = useState(null);

  useEffect(() => {
    // If no faults were passed in state, fetch them using the vehicle info
    if (faults.length === 0) {
      const fetchData = async () => {
        try {
          console.log("Fetching manual fault data with:", vehicleInfo);
          const response = await axios.get("https://car-project-backend-manual.onrender.com/api/faults", {
            params: {
              brand: vehicleInfo.brand,
              model: vehicleInfo.model,
              variant: vehicleInfo.variant,
              year: vehicleInfo.year,
              miles: vehicleInfo.miles,
              gearbox: vehicleInfo.gearbox,
            },
            timeout: 20000,
          });
          if (response.data && response.data.faults) {
            setFaults(response.data.faults);
          } else {
            setFaults([]);
          }
        } catch (err) {
          console.error("Error fetching manual faults:", err);
          setError("⚠️ Error retrieving fault data. Please try again.");
        }
        setLoading(false);
      };
      fetchData();
    }
  }, [vehicleInfo, faults.length]);

  return (
    <div style={styles.page}>
      <div style={styles.container}>
        <h2 style={styles.title}>Car Fault Report</h2>

        {/* Vehicle Information Section */}
        <div style={styles.vehicleInfo}>
          <p style={styles.carInfo}>
            <strong>Make:</strong> {vehicleInfo.brand}
          </p>
          <p style={styles.carInfo}>
            <strong>Model:</strong> {vehicleInfo.model}
          </p>
          <p style={styles.carInfo}>
            <strong>Year:</strong> {vehicleInfo.year}
          </p>
          <p style={styles.carInfo}>
            <strong>Mileage:</strong> {vehicleInfo.miles}
          </p>
          <p style={styles.carInfo}>
            <strong>Gearbox:</strong> {vehicleInfo.gearbox}
          </p>
        </div>

        {/* Common Faults Section */}
        <h3 style={styles.sectionTitle}>Common Faults:</h3>
        {loading ? (
          <p style={styles.loadingText}>Loading...</p>
        ) : faults.length > 0 ? (
          <div style={styles.faultContainer}>
            {faults.map((fault, index) => (
              <div key={index} style={styles.faultBox}>
                <p style={styles.faultText}>
                  <img
                    src="/icons/problem.png"
                    alt="Problem"
                    style={styles.iconSmall}
                  />
                  <strong>Problem:</strong> {fault.problem}
                </p>
                <p style={styles.faultText}>
                  <img
                    src="/icons/location.png"
                    alt="Location"
                    style={styles.iconSmall}
                  />
                  <strong>Location:</strong> {fault.location}
                </p>
                <p style={styles.faultText}>
                  <img
                    src="/icons/look_for.png"
                    alt="Look For"
                    style={styles.iconSmall}
                  />
                  <strong>What to Look For:</strong>
                </p>
                <p style={styles.faultDescription}>{fault.symptoms}</p>
                <p style={styles.faultText}>
                  <img
                    src="/icons/price.png"
                    alt="Price"
                    style={styles.iconSmall}
                  />
                  <strong>Estimated Repair Cost:</strong>{" "}
                  {fault.price || "N/A"}
                </p>
              </div>
            ))}
          </div>
        ) : error ? (
          <p style={styles.errorText}>{error}</p>
        ) : (
          <p style={styles.noDataText}>No fault data available.</p>
        )}

        {/* Advisory Message */}
        <p style={styles.advisory}>
          Advisory: Please consult a qualified mechanic before undertaking any repairs or making purchasing decisions.
        </p>

        {/* Back Button */}
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <button
            onClick={() => navigate("/manual-search")}
            style={styles.backButton}
          >
            ⬅ Back
          </button>
        </div>
      </div>
    </div>
  );
}

const styles = {
  page: {
    width: "100vw",
    minHeight: "100vh",
    backgroundColor: "#f4f4f4",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  container: {
    background: "white",
    padding: "20px",
    borderRadius: "10px",
    width: "90vw",
    maxWidth: "900px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
    textAlign: "left",
  },
  title: {
    fontSize: "30px",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "15px",
    color: "#333",
  },
  vehicleInfo: {
    background: "white",
    padding: "15px",
    borderRadius: "10px",
    marginBottom: "15px",
    fontSize: "15px",
    border: "15px solid #681e19",
    textAlign: "center",
  },
  carInfo: {
    fontSize: "16px",
    marginBottom: "5px",
  },
  sectionTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: "20px",
    marginBottom: "10px",
  },
  faultContainer: {
    background: "#681e19",
    padding: "15px",
    borderRadius: "10px",
  },
  faultBox: {
    marginBottom: "15px",
    padding: "15px",
    borderBottom: "1px solid #ddd",
    background: "#fff",
    borderRadius: "8px",
    boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
  },
  faultText: {
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    color: "black",
  },
  faultDescription: {
    fontSize: "14px",
    color: "#333",
    whiteSpace: "pre-line",
    marginLeft: "26px",
    display: "block",
  },
  iconSmall: {
    width: "16px",
    marginRight: "8px",
  },
  loadingText: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#555",
  },
  errorText: {
    fontSize: "16px",
    color: "red",
    fontWeight: "bold",
  },
  noDataText: {
    fontSize: "16px",
    color: "#666",
  },
  backButton: {
    backgroundColor: "#681e19",
    color: "white",
    border: "none",
    padding: "10px 15px",
    fontSize: "16px",
    cursor: "pointer",
    borderRadius: "5px",
    marginTop: "10px",
  },
  advisory: {
    textAlign: "center",
    marginTop: "20px",
    fontSize: "14px",
    color: "#681e19",
    fontStyle: "italic",
  },
};

export default ManualResults;
