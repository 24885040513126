// ErrorIcon.js
import React from "react";
import { Link } from "react-router-dom";
import errorIcon from "./error.png"; // Adjust the path if placed in a subfolder

const ErrorIcon = () => {
  return (
    <div style={styles.iconContainer}>
      <Link to="/error">
        <img src={errorIcon} alt="Error" style={styles.icon} />
      </Link>
    </div>
  );
};

const styles = {
  iconContainer: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    zIndex: 1000,
  },
  icon: {
    width: "50px",
    height: "50px",
    cursor: "pointer",
  },
};

export default ErrorIcon;
