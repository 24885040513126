import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

function Results() {
  const location = useLocation();
  const navigate = useNavigate();
  const hasFetched = useRef(false); // Prevents multiple API calls

  const [faults, setFaults] = useState([]);
  const [motHistory, setMotHistory] = useState([]);
  const [vehicleInfo, setVehicleInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Parse query parameters
  const queryParams = new URLSearchParams(location.search);
  const registration = queryParams.get("registration");
  const mileage = queryParams.get("mileage");

  useEffect(() => {
    if (hasFetched.current) return; // Prevent re-fetching
    hasFetched.current = true;

    const fetchData = async () => {
      try {
        console.log("🔄 Fetching data from backend...");

        const response = await axios.get("https://car-project-backend-auto.onrender.com/api/faults", {
          params: { registration, mileage },
          timeout: 30000, // Increased timeout to prevent timeouts
        });

        console.log("✅ Backend Response:", response.data);

        if (response.data && response.data.faults) {
          setFaults(response.data.faults);
          setError(null); // Clear error when successful
        } else {
          console.warn("⚠️ No fault data returned:", response.data);
          setFaults([]); // Keep it an empty array instead of null
        }

        if (response.data.vehicleDetails) {
          setVehicleInfo(response.data.vehicleDetails);
          setMotHistory(response.data.vehicleDetails.motHistory || []);
        }
      } catch (error) {
        console.error("❌ Error fetching data:", error);
        setError("⚠️ Error retrieving fault data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [registration, mileage]);

  // Loading screen: show full-screen overlay with rotating loading.png and "loading" text underneath.
  if (loading) {
    return (
      <div style={styles.loadingContainer}>
        <img
          src="/icons/loading.png"
          alt="Loading..."
          className="loading-spin"
          style={{ width: "100px", height: "100px" }}
        />
        <p style={styles.loadingOverlayText}>loading</p>
      </div>
    );
  }

  return (
    <div style={styles.page}>
      <div style={styles.container}>
        <h2 style={styles.title}>Car Fault Report</h2>

        {/* Display Vehicle Information */}
        {vehicleInfo && (
          <div style={styles.vehicleInfo}>
            <p style={styles.carInfo}>
              <strong>Make:</strong> {vehicleInfo.make}
            </p>
            <p style={styles.carInfo}>
              <strong>Model:</strong> {vehicleInfo.model}
            </p>
            <p style={styles.carInfo}>
              <strong>First Used:</strong> {vehicleInfo.firstUsedDate}
            </p>
            <p style={styles.carInfo}>
              <strong>Primary Colour:</strong> {vehicleInfo.primaryColour}
            </p>
            <p style={styles.carInfo}>
              <strong>Engine Size:</strong> {vehicleInfo.engineSize} cc
            </p>
            <p style={styles.carInfo}>
              <strong>Outstanding Recalls:</strong>{" "}
              {vehicleInfo.hasOutstandingRecall ? "Yes" : "No"}
            </p>
          </div>
        )}

        {/* Common Faults Section */}
        <h3 style={styles.sectionTitle}>Common Faults:</h3>
        {faults.length > 0 ? (
          <div style={styles.faultContainer}>
            {faults.map((fault, index) => (
              <div key={index} style={styles.faultBox}>
                <p style={styles.faultText}>
                  <img
                    src="/icons/problem.png"
                    alt="Problem"
                    style={styles.iconSmall}
                  />
                  <strong>Problem:</strong> {fault.problem}
                </p>
                <p style={styles.faultText}>
                  <img
                    src="/icons/location.png"
                    alt="Location"
                    style={styles.iconSmall}
                  />
                  <strong>Location:</strong> {fault.location}
                </p>
                <p style={styles.faultText}>
                  <img
                    src="/icons/look_for.png"
                    alt="Look For"
                    style={styles.iconSmall}
                  />
                  <strong>What to Look For:</strong>
                </p>
                <p style={styles.faultDescription}>{fault.symptoms}</p>
                <p style={styles.faultText}>
                  <img
                    src="/icons/price.png"
                    alt="Price"
                    style={styles.iconSmall}
                  />
                  <strong>Estimated Repair Cost:</strong>{" "}
                  {fault.costEstimate || "N/A"}
                </p>
              </div>
            ))}
          </div>
        ) : error ? (
          <p style={styles.errorText}>{error}</p>
        ) : (
          <p style={styles.noDataText}>No fault data available.</p>
        )}

        {/* MOT History Section */}
        <h3 style={styles.sectionTitle}>MOT History:</h3>
        {motHistory.length > 0 ? (
          <div style={styles.motContainer}>
            {motHistory.map((mot, index) => (
              <div key={index} style={styles.motBox}>
                <p>
                  <strong>Date:</strong> {mot.completedDate}
                </p>
                <p>
                  <strong>Result:</strong> {mot.testResult}
                </p>
                <p>
                  <strong>Odometer:</strong> {mot.odometerValue} {mot.odometerUnit}
                </p>
                <p>
                  <strong>Defects:</strong>{" "}
                  {mot.defects.length > 0
                    ? mot.defects.map((d) => d.text).join(", ")
                    : "None"}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <p style={styles.noDataText}>No MOT history available.</p>
        )}

        {/* Advisory Message */}
        <p style={styles.advisory}>
          Advisory: Please consult a qualified mechanic before undertaking any repairs or making purchasing decisions.
        </p>

        {/* Back Button */}
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <button onClick={() => navigate(-1)} style={styles.backButton}>
            ⬅ Back
          </button>
        </div>
      </div>
    </div>
  );
}

const styles = {
  page: {
    width: "100vw",
    minHeight: "100vh",
    backgroundColor: "#f4f4f4",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  container: {
    background: "white",
    padding: "20px",
    borderRadius: "10px",
    width: "90vw",
    maxWidth: "900px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
    textAlign: "left",
  },
  title: {
    fontSize: "30px",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "15px",
    color: "#333",
  },
  vehicleInfo: {
    background: "white",
    padding: "15px",
    borderRadius: "10px",
    marginBottom: "15px",
    fontSize: "15px",
    border: "15px solid #681e19",
    textAlign: "center",
  },
  carInfo: {
    fontSize: "16px",
    marginBottom: "5px",
  },
  sectionTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: "20px",
    marginBottom: "10px",
  },
  faultContainer: {
    background: "#681e19",
    padding: "15px",
    borderRadius: "10px",
  },
  faultBox: {
    marginBottom: "15px",
    padding: "15px",
    borderBottom: "1px solid #ddd",
    background: "#fff",
    borderRadius: "8px",
    boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
  },
  motContainer: {
    background: "#681e19",
    padding: "15px",
    borderRadius: "10px",
    marginTop: "20px",
  },
  motBox: {
    marginBottom: "10px",
    padding: "10px",
    borderBottom: "1px solid #ccc",
    background: "#fff",
    borderRadius: "5px",
  },
  faultText: {
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    color: "black",
  },
  faultDescription: {
    fontSize: "14px",
    color: "#333",
    whiteSpace: "pre-line",
    marginLeft: "26px",
    display: "block",
  },
  iconSmall: {
    width: "16px",
    marginRight: "8px",
  },
  loadingText: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#555",
  },
  errorText: {
    fontSize: "16px",
    color: "red",
    fontWeight: "bold",
  },
  noDataText: {
    fontSize: "16px",
    color: "#666",
  },
  backButton: {
    backgroundColor: "#681e19",
    color: "white",
    border: "none",
    padding: "10px 15px",
    fontSize: "16px",
    cursor: "pointer",
    borderRadius: "5px",
    marginTop: "10px",
  },
  advisory: {
    textAlign: "center",
    marginTop: "20px",
    fontSize: "14px",
    color: "#681e19",
    fontStyle: "italic",
  },
  // Loading screen styles
  loadingContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "#f4f4f4",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },
  loadingOverlayText: {
    marginTop: "20px",
    fontSize: "18px",
    color: "#681e19",
    fontWeight: "bold",
  },
};

export default Results;
