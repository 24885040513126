import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Landing from "./Landing";
import App from "./App";
import ManualSearch from "./ManualSearch";
import ManualResults from "./ManualResults";
import Results from "./Results";
import ErrorPage from "./ErrorPage";
import ErrorIcon from "./ErrorIcon"; // Import your error icon component

function Base() {
  const location = useLocation(); // Tracks page changes for debugging

  useEffect(() => {
    console.log(`Navigated to ${location.pathname}`);
  }, [location]);

  return (
    <div style={styles.container}>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/registration-search" element={<App />} />
        <Route path="/manual-search" element={<ManualSearch />} />
        <Route path="/manual-results" element={<ManualResults />} />
        <Route path="/results" element={<Results />} />
        <Route path="/error" element={<ErrorPage />} />
      </Routes>

      {/* Floating Error Icon on all pages */}
      <ErrorIcon />
    </div>
  );
}

// Styling for ErrorIcon position
const styles = {
  container: {
    position: "relative",
    minHeight: "100vh", // Ensures proper layout
  },
};

export default Base;
