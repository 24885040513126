import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function ManualSearch() {
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [variant, setVariant] = useState("");
  const [year, setYear] = useState("");
  const [mileage, setMileage] = useState("");
  const [gearbox, setGearbox] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!make || !model || !variant || !year || !mileage || !gearbox) {
      alert("❌ Please fill out all fields.");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.get(
        "https://car-project-backend-manual.onrender.com/api/faults",
        {
          params: { brand: make, model, variant, year, miles: mileage, gearbox },
        }
      );

      console.log("✅ Faults Received:", response.data);

      navigate(
        `/manual-results?brand=${encodeURIComponent(make)}&model=${encodeURIComponent(
          model
        )}&variant=${encodeURIComponent(variant)}&year=${encodeURIComponent(
          year
        )}&miles=${encodeURIComponent(mileage)}&gearbox=${encodeURIComponent(gearbox)}`,
        {
          state: {
            faults: response.data.faults,
            vehicleInfo: { brand: make, model, variant, year, miles: mileage, gearbox },
          },
        }
      );
    } catch (error) {
      console.error("❌ Error fetching faults:", error);
      alert("⚠️ Error retrieving fault data. Please try again.");
    }

    setLoading(false);
  };

  return (
    <div style={styles.background}>
      {/* Full-page overlay */}
      <div style={styles.overlay}></div>

      {/* Global CSS for hover effects */}
      <style>
        {`
          .hoverEffect {
            color: black !important;
            transition: color 0.3s ease, font-size 0.3s ease;
          }
          .hoverEffect:hover {
            color: #681e19 !important;
            font-size: 125% !important;
          }
          .linkedinHover {
            color: #681e19 !important;
            transition: color 0.3s ease;
          }
          .linkedinHover:hover {
            color:rgb(255, 255, 255) !important;
          }
        `}
      </style>

      <div style={styles.mainContent}>
        <div style={styles.container}>
          <h2 style={{ ...styles.title, color: "#681e19" }}>
            Find Common Faults In Your Car - MOT Not Included
            <br />
            Manual Search 
          </h2>

          <label style={styles.label} className="hoverEffect">
            Car Make:
          </label>
          <input
            type="text"
            value={make}
            onChange={(e) => setMake(e.target.value)}
            placeholder="Enter car make"
            style={styles.input}
            className="hoverEffect"
          />

          <label style={styles.label} className="hoverEffect">
            Car Model:
          </label>
          <input
            type="text"
            value={model}
            onChange={(e) => setModel(e.target.value)}
            placeholder="Enter car model"
            style={styles.input}
            className="hoverEffect"
          />

          <label style={styles.label} className="hoverEffect">
            Variant:
          </label>
          <input
            type="text"
            value={variant}
            onChange={(e) => setVariant(e.target.value)}
            placeholder="Enter variant (e.g., ST-Line, XLE)"
            style={styles.input}
            className="hoverEffect"
          />

          <label style={styles.label} className="hoverEffect">
            Year:
          </label>
          <input
            type="number"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            placeholder="Enter year (e.g., 2018)"
            style={styles.input}
            className="hoverEffect"
          />

          <label style={styles.label} className="hoverEffect">
            Mileage:
          </label>
          <input
            type="number"
            value={mileage}
            onChange={(e) => setMileage(e.target.value)}
            placeholder="Enter mileage"
            style={styles.input}
            className="hoverEffect"
          />

          <label style={styles.label} className="hoverEffect">
            Gearbox Type:
          </label>
          <select
            value={gearbox}
            onChange={(e) => setGearbox(e.target.value)}
            style={styles.input}
            className="hoverEffect"
          >
            <option value="">Select Gearbox</option>
            <option value="Automatic">Automatic</option>
            <option value="Manual">Manual</option>
          </select>

          <button onClick={handleSubmit} style={styles.button} disabled={loading}>
            {loading ? "Searching..." : "Find Faults"}
          </button>
        </div>

        {/* Back Button placed just above the footer */}
        <button style={styles.backButton} onClick={() => navigate("/")}>
          ⬅ Back to Landing Page
        </button>
      </div>

      <div style={styles.footer}>
        <p>
          Connect with me on{" "}
          <a
            href="https://www.linkedin.com/in/adithyavirakthi"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.link}
            className="linkedinHover"
          >
            LinkedIn
          </a>
        </p>
      </div>
    </div>
  );
}

const styles = {
  background: {
    position: "relative",
    backgroundImage: "url('/icons/homebackground.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between", // This pushes the footer to the bottom
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1,
  },
  mainContent: {
    position: "relative",
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "2rem",
  },
  container: {
    background: "rgba(235, 235, 235, 0.85)",
    padding: "1.5rem",
    borderRadius: "6px",
    textAlign: "center",
    width: "70vw",
    maxWidth: "320px",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontSize: "1.4rem",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  label: {
    fontSize: "1rem",
    fontWeight: "bold",
    display: "block",
    marginTop: "10px",
    marginBottom: "5px",
  },
  input: {
    width: "100%",
    padding: "8px",
    marginBottom: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontSize: "1rem",
  },
  button: {
    backgroundColor: "#681e19",
    color: "white",
    border: "none",
    padding: "10px 15px",
    fontSize: "1rem",
    cursor: "pointer",
    borderRadius: "5px",
    marginTop: "10px",
    transition: "background-color 0.3s ease-in-out",
  },
  backButton: {
    backgroundColor: "#681e19",
    color: "white",
    border: "none",
    padding: "10px 15px",
    fontSize: "1rem",
    cursor: "pointer",
    borderRadius: "5px",
    transition: "background-color 0.3s ease-in-out",
    marginTop: "1rem",
  },
  footer: {
    position: "relative",
    zIndex: 2,
    textAlign: "center",
    padding: "5px", // Thinner padding for a slimmer footer
    fontSize: "0.9rem",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    color: "white",
  },
  link: {
    color: "#1DA1F2",
    textDecoration: "none",
    fontWeight: "bold",
  },
};

export default ManualSearch;
